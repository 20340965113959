@mixin tag-custom-variant($background, $color, $border) {
  border-radius: 4px !important;
  padding: 2px 8px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;

  background-color: $background !important;
  color: $color !important;
  border: 1px solid $border !important;
}


$custom-primary-bg-color: $blue-focus3;
$custom-primary-color: $blue-400;
$custom-primary-border: $transparent;
.badge.bg-#{$custom-prefix}-primary {
  @include tag-custom-variant(
    $custom-primary-bg-color,
    $custom-primary-color,
    $custom-primary-border,
  );
}

$custom-success-bg-color: $green-focus2;
$custom-success-color: $green-400;
$custom-success-border: $transparent;
.badge.bg-#{$custom-prefix}-success {
  @include tag-custom-variant(
    $custom-success-bg-color,
    $custom-success-color,
    $custom-success-border,
  );
}

$custom-warning-bg-color: $yellow-focus2;
$custom-warning-color: $yellow-400;
$custom-warning-border: $transparent;
.badge.bg-#{$custom-prefix}-warning {
  @include tag-custom-variant(
    $custom-warning-bg-color,
    $custom-warning-color,
    $custom-warning-border,
  );
}

$custom-danger-bg-color: $red-focus2;
$custom-danger-color: $red-400;
$custom-danger-border: $transparent;
.badge.bg-#{$custom-prefix}-danger {
  @include tag-custom-variant(
    $custom-danger-bg-color,
    $custom-danger-color,
    $custom-danger-border,
  );
}

$custom-info-bg-color: $cyan-focus2;
$custom-info-color: $cyan-400;
$custom-info-border: $transparent;
.badge.bg-#{$custom-prefix}-info {
  @include tag-custom-variant(
    $custom-info-bg-color,
    $custom-info-color,
    $custom-info-border,
  );
}

$custom-defalut-bg-color: $gray-50;
$custom-defalut-color: $gray-500;
$custom-defalut-border: $transparent;
.badge.bg-#{$custom-prefix}-defalut {
  @include tag-custom-variant(
    $custom-defalut-bg-color,
    $custom-defalut-color,
    $custom-defalut-border,
  );
}
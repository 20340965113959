.gm-style {
    .gm-bundled-control-on-bottom {
      .gmnoprint {
        > div {
          background-color: $transparent !important;
          box-shadow: unset !important;
  
          > div {
            background-color: $transparent !important;
            box-shadow: unset !important;
            height: 4px !important;
          }
  
          .gm-control-active {
            border-radius: 6px !important;
            background-color: $white !important;
            width: 32px !important;
            height: 32px !important;
            border: 1px solid $gray-300 !important;
            box-shadow: $shadow1 !important;
  
            img {
              width: 15px !important;
              height: 15px !important;
            }
          }
        }
      }
}
.gm-fullscreen-control {
      border-radius: 6px !important;
      background-color: $white !important;
      width: 32px !important;
      height: 32px !important;
      border: 1px solid $gray-300 !important;
      box-shadow: $shadow1 !important;
  
      img {
        width: 15px !important;
        height: 15px !important;
      }
    }
}
.marker-label {
    background-color: $green-500;
    color: $white !important;
    font-weight: bold !important;
    font-size: 11px !important;
    padding: 3px 9px !important;
    border-radius: 20px !important;
}



.map-context-menu {
  position: fixed;
  z-index: 1;
  background-color: $white;
  padding: 10px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  ul {
    display: inline-flex;
    width: 100%;
    padding: 0px;
    margin: 0px;
    list-style-type: none;

    li {
      display: inline-flex;
      padding: 2px 1px;
      color: $secondary;
      cursor: pointer;

      svg {
        position: relative;
        top: 3px;

        path {
          stroke: $secondary;
        }
      }

      &:hover {
        color: $text-muted;

        svg {
          path {
            stroke: $text-muted;
          }
        }
      }
    }
  }
}

.custom-map-context-menu {
  position: fixed;
  z-index: 1;
}
table,
.divTable {
  border-collapse: separate;
  border-spacing: 0;
  width: fit-content;

  .border-1 {
    border: 1px solid $gray-300 !important;
  }
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: $border-radius;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: $border-radius;
}

// /* bottom-left border-radius */
// table tr:last-child td:first-child {
//   border-bottom-left-radius: $border-radius;
// }

// /* bottom-right border-radius */
// table tr:last-child td:last-child {
//   border-bottom-right-radius: $border-radius;
// }

/* bottom-left border-radius */
.border-bottom-radius-left {
  tr:last-child td:first-child {
    border-bottom-left-radius: $border-radius;
  }
}

/* bottom-right border-radius */
.border-bottom-radius-right {
  tr:last-child td:last-child {
    border-bottom-right-radius: $border-radius;
  }
}

.table-pagination {
  padding: 12px 24px 16px 24px;
  border-right: 1px solid $gray-300;
  border-left: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid $gray-300;
}

table tr th:last-child,
table tr td:last-child {
  border-right: 1px solid $gray-300;
}
table tr th,
table tr td {
  // border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}
table tr th {
  border-top: 1px solid $gray-300;
  padding: 14px 16px;
}
table tr td {
  padding: 12px 16px;
}

caption {
  caption-side: top;
  font-weight: $font-weight-semibold;
  font-size: $large-font-size;
  color: $gray-900;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;

  &[data-selected="true"] {
    background-color: $blue-focus1 !important;

    td:after {
      background-color: unset;
    }
  }
}

th,
.th {
  position: relative;
  font-weight: $font-weight-semibold;
  font-size: $small-font-size;
  color: $gray-800;
}

td,
.td {
  font-weight: $font-weight-normal;
  color: $gray-700;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}


#table_wrapper_ID {
  .MuiPaper-root {
    box-shadow: unset !important;
  }
  
  table tr {
    box-shadow: unset !important;

    th {
      &:last-child {
        border-right: 1px solid $gray-300 !important;
      }
    }
  }

  tbody {
    tr {
      width: 100% !important;
    }
  }
}

.custom-cell-line-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.Mui-TableHeadCell-Content-Wrapper {
  font-family: $font-family-sans-serif !important;
  font-weight: $font-weight-semibold !important;
  font-size: $small-font-size !important;
  color: $gray-800 !important;
  user-select: none !important;

  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}
.MuiTableCell-body {
  font-weight: $font-weight-normal !important;
  color: $gray-700 !important;
  
  align-content: center !important;

  min-height: 50px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.Mui-TableHeadCell-ResizeHandle-Divider {
  border-style: dashed !important;
  border-width: 1px !important;
  height: 30px !important;
}
// .MuiTableRow-head > th {
//   .Mui-TableHeadCell-ResizeHandle-Wrapper {
//     opacity: 0;

//     &:active {
//       opacity: 1;
//     }
//   }
//   .Mui-TableHeadCell-Content-Actions {
//     opacity: 0;
//   }

//   &:hover {
//     .Mui-TableHeadCell-ResizeHandle-Wrapper {
//       opacity: 1;
//     }
//     .Mui-TableHeadCell-Content-Actions {
//       opacity: 1;
//     }
//   }
// }
.ant-select {
  padding: 0px !important;
  box-shadow: $shadow1 !important;

  .ant-select-selector {
    background: $transparent !important;
    border: 1px solid $transparent !important;
    box-shadow: unset !important;

    .ant-select-selection-search {
      left: 0px;
    }

    .ant-select-selection-placeholder {
      color: $gray-500 !important;
    }

    .ant-select-selection-item {
      color: $gray-700 !important;
    }

    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        background-color: $blue-50 !important;

        .ant-select-selection-item-content {
          color: $blue-500 !important;
          font-weight: 500;
        }

        .ant-select-selection-item-remove {
          svg {
            path {
              fill: $blue-500 !important;
            }
          }

          &:hover {
            svg {
              path {
                fill: $blue-800 !important;
              }
            }
          }
        }
      }
    }
  }

  .ant-select-arrow {
    svg {
      path {
        fill: $gray-800 !important;
      }
    }
  }

  .ant-select-clear {
    svg {
      path {
        fill: $gray-800 !important;
      }
    }
  }


  &:hover {
    .ant-select-selector {
      border: 1px solid $transparent !important;

      .ant-select-selection-placeholder {
        color: $gray-600 !important;
      }
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: 0 0 0 0.25rem $blue-focus2 !important;
      border: 1px solid $blue-500 !important;

      .ant-select-selection-placeholder {
        color: $gray-700 !important;
      }
    }
  }

  &.ant-select-disabled {
    .ant-select-selector {
      background: $gray-200 !important;
      border: 1px solid $gray-300 !important;
      box-shadow: unset !important;

      .ant-select-selection-placeholder {
        color: $gray-500 !important;
      }

      .ant-select-selection-item {
        color: $gray-500 !important;
      }

      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background-color: $gray-50 !important;
  
          .ant-select-selection-item-content {
            color: $gray-500 !important;
          }
        }
      }
    }

    .ant-select-arrow {
      cursor: not-allowed !important;

      svg {
        path {
          fill: $gray-500 !important;
        }
      }
    }
  
    .ant-select-clear {
      cursor: not-allowed !important;

      svg {
        path {
          fill: $gray-500 !important;
        }
      }
    }

    &:hover {
      border: 1px solid $gray-300 !important;

      .ant-select-selector {
        border: 1px solid $gray-300 !important;
      }
    }
    
    +.form-text {
      color: $gray-500 !important;
    }
  }
}


.input-group:not(.ant-select-auto-complete) {
  .ant-select.form-control {
    padding-right: 0px !important;

    .ant-select-arrow {
      right: 37px;
    }

    .ant-select-clear {
      right: 37px;
    }

    &.is-invalid{
      +.input-group-text {
        position: absolute !important;
        right: 0px !important;
      }
    }
    &.is-valid{
      +.input-group-text {
        position: absolute !important;
        right: 0px !important;
      }
    }
  }

  .ant-select.form-control + .input-group-text {
    position: absolute !important;
    right: 0px !important;

    .btn {
      top: -1px;
    }
  }

  .input-group-text {
    &:first-child {
      +.ant-select.form-control, ~.ant-select.form-control {
        padding-left: 0px !important;
        .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-placeholder {
          padding-left: 35px !important;
        }
      }

      &.p-search {
        +.ant-select.form-control, ~.ant-select.form-control {
          padding-left: 0px !important;
          .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-placeholder {
            padding-left: 35px !important;
          }
        }
      }
    }
    
    &:last-child {
      +.ant-select.form-control, ~.ant-select.form-control {
        padding-left: 0px !important;
        .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-placeholder {
          padding-left: 60px !important;
        }
      }
    }
  }

  &:has(> .ant-select.ant-select-disabled){
    color: $gray-500;
    cursor: not-allowed;

    a {
      cursor: not-allowed;
    }

    svg {
      position: relative;
      top: -1px;

      path {
        stroke: $gray-500;
      }
    }
    
    +.form-text {
      color: $gray-500 !important;
    }
  }
}


.ant-select-dropdown {
  z-index: 1070;
  padding: 0px;

  .custom-select-search {
    padding: 10px;

    .input-group-text {
      &:first-child {
        +.form-control, ~.form-control {
          padding-left: 33px !important;
        }
      }
    }
  }

  .custom-select-menu {
    padding: 5px 10px;

    .ant-select-item-option {
      background-color: $white !important;
      padding: 0px;
      min-height: 35px;
      
      .ant-select-item-option-content {
        color: $gray-700 !important;
        border: 1px solid $transparent !important;
        border-radius: 0px !important;
        padding: 4px 5px !important;

        .btn {
          display: none;
        }
      }

      .ant-select-item-option-state {
        padding-right: 15px;
      }

      &-selected {
        .ant-select-item-option-content {
          color: $blue-500 !important;
          background-color: $blue-focus1;
          // font-weight: 400px !important;

          .btn {
            display: block;
          }
        }
        
        .ant-select-item-option-state {
          background-color: $blue-focus1;
        }
      }
      &-active {
        .ant-select-item-option-content {
          color: $gray-800 !important;
          background-color: $blue-focus1;
          
          .btn {
            display: block;
          }
        }

        .ant-select-item-option-state {
          background-color: $blue-focus1;
        }
      }
      &-disabled {
        .ant-select-item-option-content {
          color: $gray-500 !important;
        }
      }
    }
  }
  
  .custom-select-footer {
    padding: 5px 15px 10px 15px;

    button {
      padding: 0px;
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


// Component prefix
$custom-prefix: 'custom';


// Responsive
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1920px 
);


// Font
$font-family-sans-serif:      'Inter', sans-serif;

$font-size-root:              16px;     // affects the value of `rem`, which is used for as well font sizes, paddings, and margins
$font-size-base:              .875rem; // 14px - affects the font size of the body text

$h1-font-size:                60px;
$h2-font-size:                44px;
$h3-font-size:                36px;
$h4-font-size:                28px;
$h5-font-size:                24px;
$h6-font-size:                20px;

$large-font-size:             18px;
$medium-font-size:            16px;
$small-font-size:             12px;
$xsmall-font-size:            11px;

big, .large {
  font-size: $large-font-size;
}
small, .small {
  font-size: $small-font-size;
}
.xsmall {
  font-size: $xsmall-font-size;
}

.fs-60 {
  font-size: $h1-font-size;
}
.fs-44 {
  font-size: $h2-font-size;
}
.fs-36 {
  font-size: $h3-font-size;
}
.fs-28 {
  font-size: $h4-font-size;
}
.fs-24 {
  font-size: $h5-font-size;
}
.fs-20 {
  font-size: $h6-font-size;
}
.fs-18 {
  font-size: $large-font-size;
}
.fs-16 {
  font-size: $medium-font-size;
}
.fs-12 {
  font-size: $small-font-size;
}
.fs-11 {
  font-size: $xsmall-font-size;
}

$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;

$headings-font-weight:        $font-weight-normal;


.bold {
  font-weight: $font-weight-bold;
}
.semibold {
  font-weight: $font-weight-semibold;
}
.medium {
  font-weight: $font-weight-medium;
}
.regular {
  font-weight: $font-weight-normal;
}


// Space
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  16: 16px,
  20: 20px,
  24: 24px,
  28: 28px,
  32: 32px,
  40: 40px,
  52: 52px,
  64: 64px,
  69: 69px,
  100: 100px,
);


// Colors
$white:    #ffffff;
$black:    #000000;
$transparent: transparent;

$gray-50:  #F8FAFC;
$gray-100: #F0F0F1; // Light
$gray-200: #E9E9EA;
$gray-300: #DEDEE1;
$gray-400: #C3C4C9;
$gray-500: #A3A5AD;
$gray-600: #7F818D; // Secondary
$gray-700: #6E707E;
$gray-800: #505363;
$gray-900: #181C32; // Dark

$blue:    #185CFF; // Primary
$green:   #43936C; // Success
$cyan:    #3267E3; // Info
$yellow:  #CD7B2E; // Warning
$red:     #CB3A31; // Danger
$purple:  #9179ED;
$indigo:  #6610f2;
$pink:    #d63384;
$orange:  #fd7e14;
$teal:    #20c997;


$box-shadow:                  0px 1px 2px 0px rgba(0, 0, 0, 0.12);
$box-shadow-sm:               0px 4px 8px 0px rgba(0, 0, 0, 0.1);
$box-shadow-lg:               0px 8px 14px 0px rgba(0, 0, 0, 0.1);

$shadow1:                     0px 1px 2px 0px rgba(0, 0, 0, 0.12);
$shadow2:                     0px 4px 8px 0px rgba(0, 0, 0, 0.1);
$shadow3:                     unset;

.shadow1 {
  box-shadow: $shadow1;
}
.shadow2 {
  box-shadow: $shadow2;
}
.shadow3 {
  box-shadow: $shadow3;
}


$text-muted: $gray-500;
$link-color: $blue;
$border-color: $gray-100;
$border-radius: 6px;


$blue-50: #D1DEFF;
$blue-100: #B2C9FF;
$blue-200: #8BADFF;
$blue-300: #6592FF;
$blue-400: #3E77FF;
$blue-500: $blue;
$blue-600: #144DD4;
$blue-700: #103DAA;
$blue-800: #0C2E80;
$blue-900: #081F55;
$blue-focus1: #F6F9FF;
$blue-focus2: #BCC9EA;
$blue-focus3: #F0F5FF;

$green-50: #D9E9E2;
$green-100: #C0DBCE;
$green-200: #A1C9B5;
$green-300: #82B79D;
$green-400: #62A584;
$green-500: $green;
$green-600: #387A5A;
$green-700: #2D6248;
$green-800: #214936;
$green-900: #163124;
$green-focus1: #F5FFFA;
$green-focus2: #EDFFF6;

$cyan-50: #D6E1F9;
$cyan-100: #BBCCF6;
$cyan-200: #98B3F1;
$cyan-300: #769AEC;
$cyan-400: #5480E8;
$cyan-500: $cyan;
$cyan-600: #2A56BD;
$cyan-700: #2A56BD;
$cyan-800: #193371;
$cyan-900: #11224C;
$cyan-focus1: #F5F8FF;
$cyan-focus2: #F0F4FF;

$yellow-50: #F5E5D5;
$yellow-100: #EED3B9;
$yellow-200: #E6BD96;
$yellow-300: #DEA774;
$yellow-400: #D59151;
$yellow-500: $yellow;
$yellow-600: #AB6626;
$yellow-700: #89521F;
$yellow-800: #663D17;
$yellow-900: #44290F;
$yellow-focus1: #FFFAF5;
$yellow-focus2: #FFF6ED;

$red-50: #F5D8D6;
$red-100: #EEBDBA;
$red-200: #E59C98;
$red-300: #DC7C76;
$red-400: #D45B53;
$red-500: $red;
$red-600: #A93029;
$red-700: #872721;
$red-800: #651D18;
$red-900: #441310;
$red-focus1: #FFF5FA;
$red-focus2: #FFF1F0;

$purple-50: #E9E4FB;
$purple-100: #DAD2F9;
$purple-200: #C8BCF6;
$purple-300: #B6A6F3;
$purple-400: #A38FF0;
$purple-500: $purple;
$purple-600: #7965C5;
$purple-700: #61519E;
$purple-800: #483C76;
$purple-900: #30284F;

$body-bg: $white;
$body-color: $gray-800;


$hr-border-color: $gray-100;
$hr-opacity: 1;


$link-color: $blue;
$link-decoration: underline;
$link-shade-percentage: 20%;


$dropdown-border-color: $gray-100;
$dropdown-item-padding-y: .344rem;
$dropdown-color: $gray-600;
$dropdown-link-color: $gray-600;
$dropdown-link-hover-color: $gray-600;
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-active-color: $gray-700;
$dropdown-link-active-bg: $gray-300;
$dropdown-box-shadow: $box-shadow;


// Import
@import '~bootstrap/scss/bootstrap';
@import './buttons.scss';
@import './inputs.scss';
@import './select.scss';
@import './autocomplete.scss';
@import './colorPicker.scss';
@import './tooltip.scss';
@import './popover.scss';
@import './checkbox.scss';
@import './dateTimePicker.scss';
@import './dateRangePicker.scss';
@import './tabs.scss';
@import './modal.scss';
@import './offcanvas.scss';
@import './toast.scss';
@import './tags.scss';
@import './spin.scss';
@import './card.scss';
@import './googleMap.scss';
@import './table.scss';
@import './dropzone.scss';



// Custom style
@import './style.scss';


// Scrollbar style
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $gray-50;
}
::-webkit-scrollbar-thumb {
  background: $gray-300;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: $gray-600;
}


// Flash table rows style
.flash-create {
  td {
    -moz-animation: blinkCreate 0.7s 3;
    -o-animation: blinkCreate 0.7s 3;
    -webkit-animation: blinkCreate 0.7s 3;
    animation: blinkCreate 0.7s 3;
  }
}
.flash-remove {
  td {
    -moz-animation: blinkRemove 0.7s 3;
    -o-animation: blinkRemove 0.7s 3;
    -webkit-animation: blinkRemove 0.7s 3;
    animation: blinkRemove 0.7s 3;
  }
}
.flash-update {
  td {
    -moz-animation: blinkUpdate 0.7s 3;
    -o-animation: blinkUpdate 0.7s 3;
    -webkit-animation: blinkUpdate 0.7s 3;
    animation: blinkUpdate 0.7s 3;
  }
}

@keyframes blinkCreate {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $blue-focus1;
  }
  100% {
    background-color: transparent;
  }
}
@keyframes blinkRemove {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $red-focus1
  }
  100% {
    background-color: transparent;
  }
}
@keyframes blinkUpdate {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $yellow-focus1;
  }
  100% {
    background-color: transparent;
  }
}





// Export
:export {
  xs: 0;
  sm: 576;
  md: 768;
  lg: 992;
  xl: 1200;
  xxl: 1400;
  xxxl: 1920;
}

:root {
  --bs-blue-50: #{$blue-50};
  --bs-blue-100: #{$blue-100};
  --bs-blue-200: #{$blue-200};
  --bs-blue-300: #{$blue-300};
  --bs-blue-400: #{$blue-400};
  --bs-blue-500: #{$blue-500};
  --bs-blue-600: #{$blue-600};
  --bs-blue-700: #{$blue-700};
  --bs-blue-800: #{$blue-800};
  --bs-blue-900: #{$blue-900};
  --bs-blue-focus1: #{$blue-focus1};
  --bs-blue-focus2: #{$blue-focus2};
  --bs-blue-focus3: #{$blue-focus3};
  
  --bs-red-50: #{$red-50};
  --bs-red-100: #{$red-100};
  --bs-red-200: #{$red-200};
  --bs-red-300: #{$red-300};
  --bs-red-400: #{$red-400};
  --bs-red-500: #{$red-500};
  --bs-red-600: #{$red-600};
  --bs-red-700: #{$red-700};
  --bs-red-800: #{$red-800};
  --bs-red-900: #{$red-900};
  --bs-red-focus1: #{$red-focus1};
  --bs-red-focus2: #{$red-focus2};
  
  --bs-yellow-50: #{$yellow-50};
  --bs-yellow-100: #{$yellow-100};
  --bs-yellow-200: #{$yellow-200};
  --bs-yellow-300: #{$yellow-300};
  --bs-yellow-400: #{$yellow-400};
  --bs-yellow-500: #{$yellow-500};
  --bs-yellow-600: #{$yellow-600};
  --bs-yellow-700: #{$yellow-700};
  --bs-yellow-800: #{$yellow-800};
  --bs-yellow-900: #{$yellow-900};
  --bs-yellow-focus1: #{$yellow-focus1};
  --bs-yellow-focus2: #{$yellow-focus2};
  
  --bs-green-50: #{$green-50};
  --bs-green-100: #{$green-100};
  --bs-green-200: #{$green-200};
  --bs-green-300: #{$green-300};
  --bs-green-400: #{$green-400};
  --bs-green-500: #{$green-500};
  --bs-green-600: #{$green-600};
  --bs-green-700: #{$green-700};
  --bs-green-800: #{$green-800};
  --bs-green-900: #{$green-900};
  --bs-green-focus1: #{$green-focus1};
  --bs-green-focus2: #{$green-focus2};
  
  --bs-cyan-50: #{$cyan-50};
  --bs-cyan-100: #{$cyan-100};
  --bs-cyan-200: #{$cyan-200};
  --bs-cyan-300: #{$cyan-300};
  --bs-cyan-400: #{$cyan-400};
  --bs-cyan-500: #{$cyan-500};
  --bs-cyan-600: #{$cyan-600};
  --bs-cyan-700: #{$cyan-700};
  --bs-cyan-800: #{$cyan-800};
  --bs-cyan-900: #{$cyan-900};
  --bs-cyan-focus1: #{$cyan-focus1};
  --bs-cyan-focus2: #{$cyan-focus2};
  
  --bs-purple-50: #{$purple-50};
  --bs-purple-100: #{$purple-100};
  --bs-purple-200: #{$purple-200};
  --bs-purple-300: #{$purple-300};
  --bs-purple-400: #{$purple-400};
  --bs-purple-500: #{$purple-500};
  --bs-purple-600: #{$purple-600};
  --bs-purple-700: #{$purple-700};
  --bs-purple-800: #{$purple-800};
  --bs-purple-900: #{$purple-900};
}
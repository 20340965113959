div {
  .form-control {
    background-image: none !important;
    line-height: 20px;
    padding: 6px 12px;
    box-shadow: $shadow3;
    background-color: $white;
    color: $gray-700;
    border-color: $gray-400;
    border-radius: $border-radius;
    min-height: 35px;

    &::placeholder{
      color: $gray-500;
    }

    &:hover {
      border-color: $blue-500;

      &::placeholder{
        color: $gray-600;
      }

      &.is-invalid {
        border-color: $red-500;
      }
      
      &.is-valid {
        border-color: $green-500;
      }
    }

    &:focus, &:focus-visible {
      box-shadow: 0 0 0 0.25rem $blue-focus2 !important;
      border-color: $blue-500;

      &::placeholder{
        color: $gray-700;
      }

      &.is-invalid {
        border-color: $blue-500;
      }
      
      &.is-valid {
        border-color: $blue-500;
      }
    }

    &:disabled {
      background-color: $gray-200;
      color: $gray-500;
      cursor: not-allowed;

      &::placeholder{
        color: $gray-400;
      }

      &:hover {
        border-color: $gray-400;
      }

      &.is-invalid {
        border-color: $red-500;

        &:hover {
          border-color: $red-500;
        }
      }
      
      &.is-valid {
        border-color: $green-500;

        &:hover {
          border-color: $green-500;
        }
      }

      +.form-text {
        color: $gray-500;
      }
      
      +.invalid-feedback {
        color: $red-400;
      }
      
      +.valid-feedback {
        color: $gray-500;
      }
    }
  }

  .form-label {
    line-height: 20px;
    color: $gray-900;
    font-weight: $font-weight-semibold;
  }

  .form-text {
    line-height: 18px;
    color: $gray-800;
    font-weight: $font-weight-normal;
  }

  .invalid-feedback {
    line-height: 18px;
    color: $red-400;
    font-weight: $font-weight-normal;
  }

  .valid-feedback {
    line-height: 18px;
    color: $gray-800;
    font-weight: $font-weight-normal;
  }

  &:has(> input){
    color: $blue-900;

    svg {
      position: relative;
      top: -1px;

      path {
        stroke: $gray-900;
      }
    }

    .btn  {
      svg {
        path {
          stroke: $gray-500;
        }
      }

      &:focus, &:focus-visible {
        svg {
          path {
            stroke: $gray-500;
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: $blue-500;
          }
        }
      }
    }
  }
  &:has(> .form-control:disabled){
    color: $gray-500;
    cursor: not-allowed;

    a {
      cursor: not-allowed;
    }

    svg {
      position: relative;
      top: -1px;

      path {
        stroke: $gray-500;
      }
    }
    
    +.form-text {
      color: $gray-500 !important;
    }
  }

  
  
  .input-group {
    .form-control {
      border-radius: $border-radius !important;
      border-color: $gray-400 !important;
      min-height: 35px;
      padding-right: 45px !important;

      &:hover {
        border-color: $blue-500 !important;

        +.input-group-text, ~.input-group-text {
          color: $gray-600;

          > svg {
            path {
              stroke: $gray-600;
            }
          }

          .btn  {
            > svg {
              path {
                stroke: $gray-600 !important;
              }
            }
          }
        }
      }

      &:focus, &:focus-visible {
        +.input-group-text, ~.input-group-text {
          color: $gray-700;

          > svg {
            path {
              stroke: $gray-700;
            }
          }
        }
      }

      &:active {
        +.input-group-text, ~.input-group-text {
          color: $gray-700;

          > svg {
            path {
              stroke: $gray-700;
            }
          }

          .btn  {
            > svg {
              path {
                stroke: $gray-700;
              }
            }
          }
        }
      }


      &.is-invalid {
        border-color: $red-500 !important;

        &:hover {
          border-color: $red-500 !important;
        }
          
        &:focus, &:focus-visible {
          border-color: $blue-500 !important;

          +.input-group-text, ~.input-group-text {
            color: $gray-700;
  
            > svg {
              path {
                stroke: $gray-700 !important;
              }
            }
          }
        }
        
        &:active {
          +.input-group-text, ~.input-group-text {
            color: $gray-700;
  
            > svg {
              path {
                stroke: $gray-700 !important;
              }
            }

            .btn  {
              > svg {
                path {
                  stroke: $gray-700 !important;
                }
              }
            }
          }
        }
      }
      
      &.is-valid {
        border-color: $green-500 !important;

        &:hover {
          border-color: $green-500 !important;
          color: $gray-700;


          > svg {
            path {
              stroke: $gray-700 !important;
            }
          }

          .btn  {
            > svg {
              path {
                stroke: $gray-700 !important;
              }
            }
          }
        }

        &:focus, &:focus-visible {
          border-color: $blue-500 !important;

          +.input-group-text, ~.input-group-text {
            color: $gray-700;
  
            > svg {
              path {
                stroke: $gray-700 !important;
              }
            }
          }
        }
        
        &:active {
          +.input-group-text, ~.input-group-text {
            color: $gray-700;
  
            > svg {
              path {
                stroke: $gray-700 !important;
              }
            }

            .btn  {
              > svg {
                path {
                  stroke: $gray-700 !important;
                }
              }
            }
          }
        }
      }
    }

    .input-group-text {
      border-left: 0px !important;
      background-color: $transparent !important;
      border-color: $transparent !important;
      z-index: 6 !important;
      display: inline-block;
      color: $gray-500;

      &.border-start {
        border-left: 1px solid $gray-400 !important;
      }
      &.border-end {
        border-right: 1px solid $gray-400 !important;
      }

      &:first-child {
        position: absolute !important;
        left: 0px !important;
        padding-left: 10px;
        padding-right: 8px;

        +.form-control, ~.form-control {
          padding-left: 45px !important;
        }

        &.p-search {
          +.form-control, ~.form-control {
            padding-left: 35px !important;
          }
        }

        &:has(input[type="color"]) {
          height: 100%;
          align-items: center;
          align-self: center;
          display: flex;
        }
      }
      
      &:last-child {
        position: absolute !important;
        right: 0px !important;
        
        +.form-control, ~.form-control {
          padding-right: 60px !important;
        }
      }

      > svg {
        path {
          stroke: $gray-500 !important;
        }
      }

      .btn {
        border-color: $transparent !important;
        &.active {
          svg {
            path {
              stroke: $blue-500 !important;
            }
          }
        }
      }
    }

    &:has(> input:disabled){
      .form-control {
        border-color: $gray-400 !important;
      }

      .input-group-text {
        color: $gray-400 !important;
        cursor: not-allowed !important;

        a {
          cursor: not-allowed !important;
        }

        > svg {
          path {
            stroke: $gray-500 !important;
          }
        }
      }
    }

    &:has(> input.is-invalid){
      border-color: $red-500 !important;

      +.invalid-feedback {
        display: block;
      }
    }
    &:has(> input.is-valid){
      border-color: $red-500 !important;

      +.valid-feedback {
        display: block;
      }
    }
  }
}

  
.form-label {
  &[disabled] {
    color: $gray-500 !important;
  }
}


input[type="color"] {
  padding: 0 !important;
  border: 0 !important;
  border: none !important;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  cursor: pointer !important;
  
  &::-moz-color-swatch {
    border: none;
  }

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 0;
  }

  &::-webkit-color-swatch {
    border: none;
  }
  
  &[disabled] {
    cursor: not-allowed !important;
  }
}

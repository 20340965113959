.ant-tooltip {
    z-index: 1081;

    .ant-tooltip-arrow {
        &::before {
            background: white !important;
        }
    }
    .ant-tooltip-content {
        .ant-tooltip-inner {
            box-shadow: $shadow2 !important;
            background: white !important;
            color: $gray-800 !important;
        }
    }

    &.dark  {
        .ant-tooltip-arrow {
            &::before {
                background: $blue-800 !important;
            }
        }
        .ant-tooltip-content {
            .ant-tooltip-inner {
                box-shadow: $shadow2 !important;
                background: $blue-800 !important;
                color: white !important;
            }
        }
    }
}
$dropzone-item-width: 100%;
$dropzone-item-height: 200px;
$dropzone-item-gap: 10px;

.ant-upload-wrapper {
    display: block !important;
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0 !important;

    .ant-upload-list {
        display: grid;
        gap: $dropzone-item-gap !important;

        &::before {
            content: unset;
        }

        .ant-upload-list-item-container {
            order: 2;

            margin: 0 auto !important;
            margin-inline: 0 unset !important;
            margin-bottom: 0px !important;
            width: $dropzone-item-width !important;
            height: $dropzone-item-height !important;

            background-color: white !important;
        }

        .ant-upload-select {
            order: 1;

            margin: 0 auto !important;
            margin-inline-end: auto !important;
            margin-bottom: 0px !important;
            width: $dropzone-item-width !important;
            height: $dropzone-item-height !important;

            background-color: white !important;
            border: 1px solid $gray-400 !important;

            &:hover {
                border-color: $blue-500 !important;
            }

            &.ant-upload-disabled {
                background-color: $gray-200 !important;
                border-color: $gray-300 !important;

                button {
                    outline: 0 !important;
                }

                * {
                    cursor: not-allowed;
                }
            }
        }

        &:not(.ant-upload-list-item-container) {
            margin-bottom: 0px !important;
        }

        .ant-upload-list-item-actions {
            .anticon {
                width: unset !important;
                padding: 6px;

                box-shadow: $box-shadow-sm;
                border-radius: $border-radius;
              
                background-color: $white;
                border-color: $gray-300;

                svg {
                    font-size: 22px !important;
                    fill: $gray-700 !important;
                }
            }

            .ant-btn-icon-only {
                width: 42px !important;
            }
        }
    }
    
    &.is-invalid {
        .ant-upload-list {
            .ant-upload-select {
                border: 1px solid $red-400 !important;
            }
        }
    }
    
}

.ant-image-preview-root {
    position: fixed;
    z-index: 1050 !important;

    .ant-image-preview-wrap {
        z-index: 1050 !important;
    }
    
    .ant-image-preview-mask {
        z-index: 1050 !important;
    }
}